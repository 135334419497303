import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { CognitoGroups } from './enums/users/cognito-groups.enum';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  public version: string = require('../../package.json').version;
  noAccess = 'You do not have access to this part of the system';
  isLoggedIn: boolean = false;
  sidenavMode: 'over' | 'side' = 'side';
  isSidenavOpened: boolean = false;

  private destroy$ = new Subject<void>();

  user:
    | { id: string | null; username: string | null; email: string | null }
    | undefined;

  get isCognitoAdmin(): boolean {
    return this.authService.hasGroup(CognitoGroups.cognitoadmin);
  }

  constructor(
    private observer: BreakpointObserver,
    private router: Router,
    public authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.authService.isLoggedIn$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
      });

    this.authService.auth$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ id, username, email }) => {
        this.user = { id, username, email };
      });

    // Initialize breakpoint observer in ngOnInit
    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.sidenavMode = res.matches ? 'over' : 'side';
        this.isSidenavOpened = !res.matches;
      });
  }

  ngAfterViewInit() {
    // Apply sidenav settings after view init
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.sidenav && this.sidenav.mode === 'over') {
          this.sidenav.close();
        }
      });

    // Use setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      if (this.sidenav) {
        this.sidenav.mode = this.sidenavMode;
        if (this.isSidenavOpened) {
          this.sidenav.open();
        } else {
          this.sidenav.close();
        }
        this.cdr.detectChanges();
      }
    });
  }

  toggleSidenav(): void {
    if (this.sidenav) {
      this.sidenav.toggle();
    }
  }

  public signOut(): void {
    this.authService.signOut();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

