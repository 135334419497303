<mat-toolbar class="mat-elevation-z8" *ngIf="isLoggedIn">
  <div class="d-inline-block align-text-top" style="color: white; font-size: 8px;">Version {{version}}</div>
  <button
    mat-icon-button
    *ngIf="sidenavMode === 'over'"
    (click)="toggleSidenav()"
  >
    <mat-icon style="color: white;" *ngIf="!sidenav?.opened"> menu </mat-icon>
    <mat-icon style="color: white;" *ngIf="sidenav?.opened"> close </mat-icon>
  </button>

  <div class="container-fluid d-flex">
    <a class="navbar-brand" href="#">
      <img src="assets/image/logo.svg" alt="" height="36" class="d-inline-block align-text-top align-text-left" />
    </a>
  <span class="pointer user-info d-flex" (click)="signOut()" style="color: #f7f410; margin-top: 5px; margin-left: auto" >Log out <mat-icon class="ms-1" style="margin-top: 5px;">exit_to_app</mat-icon></span>
</div>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav *ngIf="isLoggedIn" #sidenav="matSidenav" class="mat-elevation-z8">
  <ng-container>
    <avatar class="avatar mat-elevation-z8" [dimensionCircle]="70" [fontSize]="23" [username]="user?.email ?? ''"></avatar>

    <!-- <h5 class="name">{{user?.username}}</h5> -->
    <p class="designation">{{user?.email}}</p>

    <mat-divider></mat-divider>

    <button mat-button class="menu-button" routerLink="/locations" routerLinkActive="menu-active">
      <mat-icon style="color: white;" >home</mat-icon>
      <span style="color: white;">Locations</span>
    </button>
    <button mat-button class="menu-button" routerLink="/containers" routerLinkActive="menu-active">
      <mat-icon style="color: white;" >directions_boat</mat-icon>
      <span style="color: white;" >Containers</span>
    </button>
    <button mat-button [ngClass]="{ 'disabled': !isCognitoAdmin }" class="menu-button" [disabled]="!isCognitoAdmin" routerLink="/user-management" routerLinkActive="menu-active">
      <mat-icon [style.color]="isCognitoAdmin ? 'white' : 'gray'" >person</mat-icon>
      <span [style.color]="isCognitoAdmin ? 'white' : 'gray'">User management</span>
      <i *ngIf="!isCognitoAdmin" style="margin-left: 5px;" data-toggle="tooltip" data-placement="top" [title]="noAccess" class="bi bi-shield-exclamation"></i>
    </button>
    <button mat-button [ngClass]="{ 'disabled': !isCognitoAdmin }" class="menu-button" [disabled]="!isCognitoAdmin" routerLink="/identity-service" routerLinkActive="menu-active">
      <mat-icon [style.color]="isCognitoAdmin ? 'white' : 'gray'" >person</mat-icon>
      <span [style.color]="isCognitoAdmin ? 'white' : 'gray'">Identity service</span>
      <i *ngIf="!isCognitoAdmin" style="margin-left: 5px;" data-toggle="tooltip" data-placement="top" [title]="noAccess" class="bi bi-shield-exclamation"></i>
    </button>
    <!-- <button mat-button class="menu-button" routerLink="/edi-fact" routerLinkActive="menu-active">
      <mat-icon style="color: white;" >list_alt</mat-icon>
      <span style="color: white;" >EDIFACT</span>
    </button> -->

    <mat-divider></mat-divider>

    <button mat-button class="menu-button">
      <mat-icon style="color:white">help</mat-icon>
      <span style="color:white">Help</span>
    </button>
  </ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="content mat-elevation-z8">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
