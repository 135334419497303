import { Component, NgZone, OnInit, OnDestroy, AfterViewInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, Hub } from 'aws-amplify';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/auth/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();
  isLoading = true;

  constructor(
    private router: Router,
    private zone: NgZone,
    private spinner: NgxSpinnerService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.setupAuthListeners();

    this.checkCurrentUser();

    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  ngAfterViewInit(): void {
    // Force the component to be visible after render
    setTimeout(() => {
      const authElement = document.querySelector('amplify-authenticator');
      if (authElement) {
        this.renderer.setStyle(authElement, 'display', 'block');
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupAuthListeners(): void {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'signIn') {
        this.spinner.show();
        this.zone.run(() => this.router.navigate(['/locations']));
      } else if (event === 'signOut') {
        this.spinner.hide();
      }
    });
  }

  private checkCurrentUser(): void {
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.spinner.show();
        this.router.navigate(['/locations'], { replaceUrl: true });
      })
      .catch(() => {
        this.spinner.hide();
      });
  }
}
