<div class="auth-wrapper" style="position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 999; display: flex; justify-content: center; align-items: center;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="card shadow-lg border-0">
          <div class="card-header bg-white text-center p-4">
            <img src="assets/image/logo.svg" alt="Logo" class="img-fluid" style="max-height: 60px;" />
          </div>
          <div class="card-body">
            <!-- Loading spinner while authenticator loads -->
            <div class="auth-loading text-center py-4" *ngIf="isLoading">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <p class="mt-2">authentication...</p>
            </div>

            <amplify-authenticator [loginMechanisms]="['email']" initialState="signIn" *ngIf="!isLoading">
              <ng-template amplifySlot="authenticated" let-user="user" let-signOut="signOut">
                <div style="display: none;"></div>
              </ng-template>
            </amplify-authenticator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
